/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"

import Header from "../Header"
import Footer from "../Footer"
import "../../assets/styles/main.scss"
import { AnimatePresence, motion } from "framer-motion"

// Page transitions
const transition = { duration: 0.3 }

export const variants = {
  initial: { opacity: 0 },
  enter: { opacity: 1, transition },
  exit: {
    opacity: 0,
    transition: { duration: 0.3, ...transition }
  }
}

const Layout = ({ children, path }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          address
          menuLinks {
            name
            link
          }
        }
      }
    }
  `)

  // siteMetadata
  let siteData = data.site.siteMetadata
  // MDX component shortcodes
  const shortcodes = { }
  
  return (
    <>
      <motion.div 
        key="header"
        variants={variants}
        initial={false}
        animate="enter"
        exit="exit"
        transition={{ duration: 0.3 }}
      >
        <Header
          path={path}
          siteTitle={siteData.title}
          menuLinks={siteData.menuLinks}
        />
      </motion.div>

      <AnimatePresence exitBeforeEnter initial={false}>
        <motion.main 
          key={path}
          variants={variants}
          initial="initial"
          animate="enter"
          exit="exit"
        >
          <MDXProvider components={shortcodes}>
            <main>{children}</main>
          </MDXProvider>
        </motion.main>
      </AnimatePresence>
      
      <Footer siteTitle={siteData.title} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isHomepage: PropTypes.bool,
}

export default Layout

import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import MainNav from "./MainNav"
import { useStaticQuery, graphql } from "gatsby"
import Image from "./core/Image"

const Header = ({ siteTitle, menuLinks, path }) => {
  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: {eq: "assets/images/regina-logo.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }`)

  const { fluid: logo } = data.logo.childImageSharp

  // Scroll animation
  if (typeof window !== "undefined") {
    const bodyStyle = document.body.style
    let setScroll = () => {
      bodyStyle.setProperty(
        "--scroll",
        window.pageYOffset / (document.body.offsetHeight - window.innerHeight)
      )
    }
    window.addEventListener("scroll", setScroll, false)
    setScroll()
  }

  return (
    <header
      id="header"
      className="container site-header"
    >
      <Link to="/">
        <Image
          className={"site-header__logo"}
          data={logo}
          alt={siteTitle}
        />
      </Link>

      <MainNav menuLinks={menuLinks} path={path} />
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  menuLinks: PropTypes.array,
}

Header.defaultProps = {
  siteTitle: ``,
  menuLinks: [],
}

export default Header
